import ApplicationController from "./application_controller";
import axios from "axios";
import { DateTime } from "luxon";
import ReconnectingWebSocket from "reconnecting-websocket";

export default class extends ApplicationController {
  static targets = [
    "dashboardUpcomingEvents",
    "upcomingEvents",
    "cloudRecordings"
  ];

  static values = {
    hubUrl: String,
    userId: String,
    env: String,
    view: String
  };

  async initialize() {
    await axios
      .get(
        this.hubUrlValue,
        {},
        {
          credentials: "include",
        }
      )
      .then((response) => {
        const baseUrl = response.data.base_url;
        if (!this.socket) {
          const webSocketProtocol =
            this.envValue === "development" ? "ws" : "wss";
          this.socket = new ReconnectingWebSocket(
            `${webSocketProtocol}:${baseUrl}/websocket`
          );
          this.socket.onopen = ({ target }) => {
            console.log("[hub] Connection established");
            this.socket.send(JSON.stringify({ type: "hubConnect", data: {} }));
            this.socket.send(
              JSON.stringify({
                type: "hubSubscribe",
                channel: `${this.envValue};user;${this.userIdValue}`
              })
            );
          };
          this.socket.onmessage = (e) => {
            const payload = JSON.parse(e.data);
            switch (payload.type) {
              case "event:created":
              case "event:updated":
              case "event:destroyed":
              case "event:past":
                console.log(`[hub] ${payload.type}: ${JSON.stringify(payload.data)}`);
                this.reloadEventFrame();
                this.reloadDashboardUpcomingEvents();
                break;
              case "broadcast:saved":
              case "recording:created":
              case "recording:transcoded":
              case "recording:updated":
              case "collection:recording_removed":
                console.log(`[hub] ${payload.type}: ${JSON.stringify(payload.data)}`);
                this.reloadRecordingsFrame();
                break;
              case "event:upcoming":
                console.log(`[hub] ${payload.type}: ${JSON.stringify(payload.data)}`);
                this.updateUpcomingEvent(payload.data.event);
                break;
              case "recording:destroyed":
                console.log(`[hub] ${payload.type}: ${payload.data}`);
                this.reloadRecordingsFrame(true);
                break;
              default:
                console.log(`[hub] Unknown message type: ${payload.type}`);
            }
          };
          this.socket.onclose = (event) => {
            if (event.wasClean) {
              console.log(
                `[hub] Connection closed cleanly, code=${event.code} reason=${event.reason}`
              );
            } else {
              // e.g. server process killed or network down
              // event.code is usually 1006 in this case
              console.log("[hub] Connection died");
            }
          };
        }
      })
      .catch((error) => {
        console.log("Error connecting:", error);
      });
  }

  updateUpcomingEvent(event) {
    const eventToUpdate = document.getElementById(`event_${event.id}`);
    if (eventToUpdate) {
      const startsAt = DateTime.fromISO(event.starts_at);
      const endsAt = DateTime.fromISO(event.ends_at);
      if (startsAt <= DateTime.now() && endsAt > DateTime.now()) {
        eventToUpdate.classList.add('list__item--featured');
        const nowButton = `<div class="flex__col list__actions--single">
          <span class="button button--tiny">Now</span>
        </div>`;
        eventToUpdate.querySelector(':scope > a').insertAdjacentHTML('beforeend', nowButton);
      }
    } else {
      this.reloadDashboardUpcomingEvents();
    }
  }

  reloadDashboardUpcomingEvents() {
    if (this.hasDashboardUpcomingEventsTarget) {
      this.dashboardUpcomingEventsTarget.reload();
    }
  }

  reloadEventFrame() {
    if (this.hasUpcomingEventsTarget) {
      this.upcomingEventsTarget.setAttribute("src", "");
      this.upcomingEventsTarget.setAttribute(
        "src",
        this.upcomingEventsTarget.dataset.src
      );
    }
  }

  reloadRecordingsFrame(deleting = false) {
    // If deleting on a show view, no need to reload the frame
    if (deleting && this.viewValue) {
      if (this.viewValue === 'recordings_show') return;
    }
    if (this.hasCloudRecordingsTarget) {
      this.cloudRecordingsTarget.setAttribute("src", "");
      this.cloudRecordingsTarget.setAttribute(
        "src",
        this.cloudRecordingsTarget.dataset.src
      );
    }
  }
}
