import ApplicationController from "./application_controller";
import { postMessage } from "vendor/helpers";

export default class extends ApplicationController {
  static targets = [];

  static values = {
    accessibleChannels: String,
  };

  connect() {
    if (this.accessibleChannelsValue === "true") {
      postMessage("hasAccessibleChannels");
    }
  }
}
