import ApplicationController from './application_controller';
import { clearCache } from '@hotwired/turbo';

export default class extends ApplicationController {
  static targets = [
    'requiredRow',
    'submitButton'
  ];

  static values = {
    scrollOnError: Boolean
  }

  connect() {
    this.validateFields();
  }

  validateFields() {
    const requiredInputSelectors = 'textarea:required, input:required';

    this.requiredRowTargets.forEach(row => {
      const input = row.querySelector('.form__input');
      const inputLabel = row.querySelector('.form__label');
      const inputError = row.querySelector('.form__error');

      input.addEventListener('input', function(e) {
        if(input.value.length > 0 && input.validity.valid) {
          input.classList.remove('form__input--error');
          inputLabel.classList.remove('form__label--error');
          if(inputError) {
            inputError.setAttribute('aria-hidden', true);
            inputError.classList.add('hidden');
          }
        }
      });

      input.addEventListener('blur', function(e) {
        if(!input.validity.valid) {
          input.classList.add('form__input--error');
          inputLabel.classList.add('form__label--error');
          if(inputError) {
            inputError.setAttribute('aria-hidden', false);
            inputError.classList.remove('hidden');
          }
        }
      });

      input.addEventListener('invalid', function(e) {
        e.preventDefault();
        inputLabel.classList.add('form__label--error');
        input.classList.add('form__input--error');
        if(inputError) {
          inputError.setAttribute('aria-hidden', false);
          inputError.classList.remove('hidden');
          if (this.scrollOnErrorValue) {
            document.body.scrollIntoView({ behavior: 'smooth' });
          }
        }
      });
    });
  }

  clearInputValue(event) {
    const input = event.currentTarget.parentNode.querySelector('.form__input');
    input.value = '';
    input.focus();
  }

  reset(event) {
    console.log('Reset form', event);
    if(event.detail.success) {
      this.element.reset();
      clearCache();
    }
  }

  submit(e) {
    const isApple = /apple/i.test(navigator.vendor);
    const form = this.element;
    // This is a nasty hack, but safari disables DOM updates while a form is submitting :(
    // Handle data-disable-with in Safari - https://github.com/rails/jquery-ujs/issues/306#issuecomment-355751339
    if(isApple && !this.element.classList.contains('form--ajax')) {
      if(this.submitButtonTarget.dataset.disableWith) {
        e.preventDefault();
        const submitButtonHTML = this.submitButtonTarget.innerHTML;
        this.submitButtonTarget.classList.add('button--loading');
        this.submitButtonTarget.disabled = true;
        this.submitButtonTarget.innerHTML = this.submitButtonTarget.dataset.disableWith;
        const that = this;
        
        setTimeout(function() {
          form.submit();
          clearCache();
        }, 300);

        setTimeout(function () {
          if (that.hasSubmitButtonTarget) {
            that.submitButtonTarget.disabled = false;
            that.submitButtonTarget.innerHTML = submitButtonHTML;
            that.submitButtonTarget.classList.remove('button--loading');
          }
        }, 2000);
      }
    }
  }
}
